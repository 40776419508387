export const useFormCollapseStore = defineStore({
  id: 'formCollapse',
  state: () => ({
    isFormOpened: true,
  }),
  actions: {
    updatePreset() {
      const route = useRoute();
      const localePath = useLocalePath();

      this.isFormOpened = route.path === localePath('/');
    },

    open() {
      this.isFormOpened = true;
    },
    close() {
      this.isFormOpened = false;
    },
  },
});
