<script setup>
import { ModalsContainer } from 'vue-final-modal';

import localStorageKeys from '@/constants/local-storage-keys';
import routes from '@/constants/routes';

// i18n seo
const head = useLocaleHead({
  addSeoAttributes: true,
});
useHead({
  htmlAttrs: () => head.value.htmlAttrs,
});

// Cookies
const authStore = useAuthStore();

const showCookiesBanner = ref(false);
const isCookiesAccepted = useLocalStorage(
  localStorageKeys.COOKIES_ACCEPTED,
  false,
);

const route = useRoute();

onMounted(async () => {
  const {
    public: { gtmID },
  } = useRuntimeConfig();

  const metrics = useMetrics({ gtmID });
  metrics.init();

  if (isCookiesAccepted.value) {
    try {
      await gtagPushConsent('consent', 'update', {
        ad_storage: 'granted',
        analytics_storage: 'granted',
        ad_user_data: 'granted',
        ad_personalization: 'granted',
        functionality_storage: 'granted',
        personalization_storage: 'granted',
      });

      await authStore.updateSessionInformation();
    } catch (error) {
      console.log(error);
    }
  }

  watch(
    () => route.path,
    (routePath) => {
      setTimeout(() => {
        showCookiesBanner.value = !routePath.includes(routes.CART_PROCESSING);
      }, 5000);
    },
    { immediate: true },
  );
});
</script>

<template>
  <div id="app" class="app">
    <NuxtLayout v-slot="{ className }" class="app__layout">
      <NuxtPage :class="className" />
    </NuxtLayout>

    <ModalsContainer />

    <Transition name="fade">
      <BundlesCookies
        v-if="!isCookiesAccepted && showCookiesBanner"
        class="app__cookies"
      />
    </Transition>
  </div>
</template>

<style scoped lang="scss">
.app {
  $parent: &;

  display: flex;
  flex-direction: column;

  &:deep(#{$parent}__layout) {
    flex-grow: 1;
  }

  &__cookies {
    position: fixed;
    bottom: em($gap-sm);
    left: 50%;
    z-index: 10;

    @include media-breakpoint-up(sm) {
      max-width: em(360);
      transform: translateX(-50%);
    }

    @include media-breakpoint-down(sm) {
      right: em($gap-sm);
      left: em($gap-sm);
    }
  }
}
</style>
