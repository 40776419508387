import * as Sentry from '@sentry/vue';

/**
 * Sentry integration
 * @description Log browser errors to sentry server
 * @description Provide SENTRY_DSN and SENTRY_ENVIRONMENT env variables
 */
export default defineNuxtPlugin({
  parallel: true,
  setup(nuxtApp) {
    const {
      public: {
        sentry: { enabled, dsn, environment },
      },
    } = useRuntimeConfig();

    if (!enabled || !dsn) return;

    const router = useRouter();

    Sentry.init({
      app: nuxtApp.vueApp,
      dsn,
      environment,
      integrations: [Sentry.browserTracingIntegration({ router })],
      tracesSampleRate: 1,
    });
  },
});
