import { currenciesAdapter } from '@/adapters/currencies';
import { defaultCurrencyId } from '@/constants/currency';

export const useCurrencyStore = defineStore({
  id: 'currency',
  state: () => ({
    currencyId: defaultCurrencyId,

    currencies: [],
    currenciesFetched: false,
  }),
  getters: {
    currencyInfo() {
      return this.currencies.find(
        (currency) => currency.id === this.currencyId,
      );
    },
  },
  actions: {
    setCurrencyId(currencyId, currencyIdCookie = null) {
      if (!currencyIdCookie) currencyIdCookie = useCookie('currencyId');
      currencyIdCookie.value = currencyId;
      this.currencyId = currencyId;
    },

    fetchCurrencies() {
      return useRequest('/currencies').then((data) => {
        this.currencies = currenciesAdapter(data);
        this.currenciesFetched = true;
      });
    },
  },
});
