import { WS } from '@/services/centrifuge';

export default defineNuxtPlugin({
  parallel: true,
  setup() {
    const {
      public: { wsEndpoint },
    } = useRuntimeConfig();

    if (!wsEndpoint) return;

    WS.init(wsEndpoint);
  },
});
