export const LANGUAGES = Object.freeze({
  'en-US': {
    language: 'en',
    region: 'US',
    script: '',
    isRtl: false,
    name: 'English (US)',
    localizedName: 'English (United States)',
  },
  'de-DE': {
    language: 'de',
    region: 'DE',
    script: '',
    isRtl: false,
    name: 'German (Germany)',
    localizedName: 'Deutsch (Germany)',
  },
  'es-ES': {
    language: 'es',
    region: 'ES',
    script: '',
    isRtl: false,
    name: 'European Spanish',
    localizedName: 'Español (Spain)',
  },
  'fr-FR': {
    language: 'fr',
    region: 'FR',
    script: '',
    isRtl: false,
    name: 'French (France)',
    localizedName: 'Français (France)',
  },
  'it-IT': {
    language: 'it',
    region: 'IT',
    script: '',
    isRtl: false,
    name: 'Italian (Italy)',
    localizedName: 'Italiano (Italy)',
  },
  'pl-PL': {
    language: 'pl',
    region: 'PL',
    script: '',
    isRtl: false,
    name: 'Polish (Poland)',
    localizedName: 'Polski (Poland)',
  },
  'pt-PT': {
    language: 'pt',
    region: 'PT',
    script: '',
    isRtl: false,
    name: 'European Portuguese',
    localizedName: 'Português (Portugal)',
  },
  'ru-RU': {
    language: 'ru',
    region: 'RU',
    script: '',
    isRtl: false,
    name: 'Russian (Russia)',
    localizedName: 'Русский (Russia)',
  },
  'uk-UA': {
    language: 'uk',
    region: 'UA',
    script: '',
    isRtl: false,
    name: 'Ukrainian (Ukraine)',
    localizedName: 'Українська (Ukraine)',
  },
  'ja-JP': {
    language: 'ja',
    region: 'JP',
    script: '',
    isRtl: false,
    name: 'Japanese (Japan)',
    localizedName: '日本語 (Japan)',
  },
  'ko-KR': {
    language: 'ko',
    region: 'KR',
    script: '',
    isRtl: false,
    name: 'Korean (South Korea)',
    localizedName: '한국어 (South Korea)',
  },
  'zh-HK': {
    language: 'zh',
    region: 'HK',
    script: 'Hans',
    isRtl: false,
    name: 'Chinese (Hong Kong SAR China, Simplified)',
    localizedName: '中文 (China)',
  },
  // 'ar-EG': {
  //   language: 'ar',
  //   region: 'EG',
  //   script: '',
  //   isRtl: true,
  //   name: 'Arabic (Egypt)',
  //   localizedName: 'العربية (مصر)',
  // },
  // 'bg-BG': {
  //   language: 'bg',
  //   region: 'BG',
  //   script: '',
  //   isRtl: false,
  //   name: 'Bulgarian (Bulgaria)',
  //   localizedName: 'Български (България)',
  // },
  // 'ca-AD': {
  //   language: 'ca',
  //   region: 'AD',
  //   script: '',
  //   isRtl: false,
  //   name: 'Catalan (Andorra)',
  //   localizedName: 'Català (Andorra)',
  // },
  // 'cs-CZ': {
  //   language: 'cs',
  //   region: 'CZ',
  //   script: '',
  //   isRtl: false,
  //   name: 'Czech (Czechia)',
  //   localizedName: 'Čeština (Česko)',
  // },
  // 'da-DK': {
  //   language: 'da',
  //   region: 'DK',
  //   script: '',
  //   isRtl: false,
  //   name: 'Danish (Denmark)',
  //   localizedName: 'Dansk (Danmark)',
  // },

  // 'el-GR': {
  //   language: 'el',
  //   region: 'GR',
  //   script: '',
  //   isRtl: false,
  //   name: 'Greek (Greece)',
  //   localizedName: 'Ελληνικά (Ελλάδα)',
  // },

  // 'et-EE': {
  //   language: 'et',
  //   region: 'EE',
  //   script: '',
  //   isRtl: false,
  //   name: 'Estonian (Estonia)',
  //   localizedName: 'Eesti (Eesti)',
  // },
  // 'fa-IR': {
  //   language: 'fa',
  //   region: 'IR',
  //   script: '',
  //   isRtl: true,
  //   name: 'Persian (Iran)',
  //   localizedName: 'فارسی (ایران)',
  // },
  // 'fil-PH': {
  //   language: 'fil',
  //   region: 'PH',
  //   script: '',
  //   isRtl: false,
  //   name: 'Filipino (Philippines)',
  //   localizedName: 'Filipino (Pilipinas)',
  // },

  // 'he-IL': {
  //   language: 'he',
  //   region: 'IL',
  //   script: '',
  //   isRtl: true,
  //   name: 'Hebrew (Israel)',
  //   localizedName: 'עברית (ישראל)',
  // },
  // 'hi-IN': {
  //   language: 'hi',
  //   region: 'IN',
  //   script: '',
  //   isRtl: false,
  //   name: 'Hindi (India)',
  //   localizedName: 'हिन्दी (भारत)',
  // },
  // 'hr-HR': {
  //   language: 'hr',
  //   region: 'HR',
  //   script: '',
  //   isRtl: false,
  //   name: 'Croatian (Croatia)',
  //   localizedName: 'Hrvatski (Hrvatska)',
  // },
  // 'hu-HU': {
  //   language: 'hu',
  //   region: 'HU',
  //   script: '',
  //   isRtl: false,
  //   name: 'Hungarian (Hungary)',
  //   localizedName: 'Magyar (Magyarország)',
  // },
  // 'is-IS': {
  //   language: 'is',
  //   region: 'IS',
  //   script: '',
  //   isRtl: false,
  //   name: 'Icelandic (Iceland)',
  //   localizedName: 'Íslenska (Ísland)',
  // },

  // 'mk-MK': {
  //   language: 'mk',
  //   region: 'MK',
  //   script: '',
  //   isRtl: false,
  //   name: 'Macedonian (North Macedonia)',
  //   localizedName: 'Македонски (Северна Македонија)',
  // },
  // 'ms-MY': {
  //   language: 'ms',
  //   region: 'MY',
  //   script: '',
  //   isRtl: false,
  //   name: 'Malay (Malaysia)',
  //   localizedName: 'Melayu (Malaysia)',
  // },
  // 'nb-NO': {
  //   language: 'nb',
  //   region: 'NO',
  //   script: '',
  //   isRtl: false,
  //   name: 'Norwegian Bokmål (Norway)',
  //   localizedName: 'Norsk bokmål (Norge)',
  // },
  // 'nl-NL': {
  //   language: 'nl',
  //   region: 'NL',
  //   script: '',
  //   isRtl: false,
  //   name: 'Dutch (Netherlands)',
  //   localizedName: 'Nederlands (Nederland)',
  // },

  // 'pt-BR': {
  //   language: 'pt',
  //   region: 'BR',
  //   script: '',
  //   isRtl: false,
  //   name: 'Brazilian Portuguese',
  //   localizedName: 'Português (Brasil)',
  // },

  // 'ro-RO': {
  //   language: 'ro',
  //   region: 'RO',
  //   script: '',
  //   isRtl: false,
  //   name: 'Romanian (Romania)',
  //   localizedName: 'Română (România)',
  // },

  // 'sk-SK': {
  //   language: 'sk',
  //   region: 'SK',
  //   script: '',
  //   isRtl: false,
  //   name: 'Slovak (Slovakia)',
  //   localizedName: 'Slovenčina (Slovensko)',
  // },
  // 'sr-RS': {
  //   language: 'sr',
  //   region: 'RS',
  //   script: 'Cyrl',
  //   isRtl: false,
  //   name: 'Serbian (Serbia, Cyrillic)',
  //   localizedName: 'Српски (Србија, Ћирилица)',
  // },
  // 'sv-SE': {
  //   language: 'sv',
  //   region: 'SE',
  //   script: '',
  //   isRtl: false,
  //   name: 'Swedish (Sweden)',
  //   localizedName: 'Svenska (Sverige)',
  // },
  // 'th-TH': {
  //   language: 'th',
  //   region: 'TH',
  //   script: '',
  //   isRtl: false,
  //   name: 'Thai (Thailand)',
  //   localizedName: 'ไทย (ไทย)',
  // },
  // 'tr-TR': {
  //   language: 'tr',
  //   region: 'TR',
  //   script: '',
  //   isRtl: false,
  //   name: 'Turkish (Turkey)',
  //   localizedName: 'Türkçe (Türkiye)',
  // },

  // 'vi-VN': {
  //   language: 'vi',
  //   region: 'VN',
  //   script: '',
  //   isRtl: false,
  //   name: 'Vietnamese (Vietnam)',
  //   localizedName: 'Tiếng Việt (Việt Nam)',
  // },
});

export const LANGUAGE_KEYS = Object.values(LANGUAGES).reduce(
  (acc, config) => ({ ...acc, [config.language]: null }),
  {},
);
export const LANGUAGE_CODES = Object.values(LANGUAGES).map(
  (config) => config.language,
);

const baseLangFile = 'load.js';
export const I18N_LOCALES = Object.entries(LANGUAGES).map(([iso, config]) => ({
  code: config.language,
  iso,
  files: config.language === 'en' ? ['en.json', baseLangFile] : [baseLangFile],

  // view only
  region: config.region,
  name: config.name,
  localizedName: config.localizedName,
}));
export const I18N_DEFAULT_LOCALE = LANGUAGES['en-US'].language;
export const I18N_REDIRECT_ROUTE = 'r';

export const LOCALES = Object.freeze({
  ar: 'ar-EG',
  bg: 'bg-BG',
  ca: 'ca-AD',
  cs: 'cs-CZ',
  da: 'da-DK',
  de: 'de-DE',
  el: 'el-GR',
  en: 'en-US',
  es: 'es-ES',
  et: 'et-EE',
  fa: 'fa-IR',
  fil: 'fil-PH',
  fr: 'fr-FR',
  he: 'he-IL',
  hi: 'hi-IN',
  hr: 'hr-HR',
  hu: 'hu-HU',
  is: 'is-IS',
  it: 'it-IT',
  ja: 'ja-JP',
  ko: 'ko-KR',
  mk: 'mk-MK',
  ms: 'ms-MY',
  nb: 'nb-NO',
  nl: 'nl-NL',
  pl: 'pl-PL',
  pt: 'pt-PT',
  ro: 'ro-RO',
  sk: 'sk-SK',
  sr: 'sr-RS',
  sv: 'sv-SE',
  th: 'th-TH',
  tr: 'tr-TR',
  uk: 'uk-UA',
  vi: 'vi-VN',
  zh: 'zh-HK',
});

export const DEFAULT_LANG = LOCALES.en;

export const DEFAULT_NS = 'errors';

export const LANGUAGE_COOKIE_KEY = 'locale';
