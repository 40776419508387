export const currencyAdapter = (data) => {
  return {
    id: data.id,
    name: data.name,
    symbol: data.symbol,
    exchangeRate: data.exchange_rate,
  };
};

export const currenciesAdapter = (items) => {
  return items.map(currencyAdapter);
};
