const scripts = {
  gtm: {
    setDefault() {
      return `window.dataLayer = window.dataLayer || [];
			function gtagPushConsent(){dataLayer.push(arguments);}

			gtagPushConsent('consent', 'default', {
				'ad_storage': 'denied',
				'ad_user_data': 'denied',
				'ad_personalization': 'denied',
				'analytics_storage': 'denied'
			});`;
    },
    script(gtmID) {
      return `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${gtmID}');`;
    },
    noscript(gtmID) {
      return `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmID}"
        height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
    },
  },
};

function setMetric(metricName, metricId) {
  // set default
  const scriptDefault = document.createElement('script');
  scriptDefault.innerHTML = scripts[metricName].setDefault();
  document.head.appendChild(scriptDefault);

  // add script
  const script = document.createElement('script');
  script.innerHTML = scripts[metricName].script(metricId);
  document.head.appendChild(script);

  // add noscript
  const noscript = document.createElement('noscript');
  noscript.innerHTML = scripts[metricName].noscript(metricId);
  document.body.prepend(noscript);
}

export const useMetrics = ({ gtmID }) => {
  return {
    init() {
      if (gtmID) setMetric('gtm', gtmID);
    },
  };
};
