import analyticEventKeys from '@/constants/analytic-event-keys';

export const useGoogleAnalytics = () => {
  let date = new Date();
  const expirationDays = 365;
  date.setTime(date.getTime() + expirationDays * 24 * 60 * 60 * 1000);

  const userId = useCookie('userId', { expires: date });

  // Events
  function setUserId() {
    if (!window.dataLayer) return;

    userId.value = generateUUID();
    return pushEvent({ event: analyticEventKeys.USER_ID_SET });
  }

  async function pushEvent({
    event = '',
    label = '',
    eventContext = '',
    ...data
  }) {
    if (!window.dataLayer) return;

    if (!userId.value) setUserId();

    try {
      await window.dataLayer.push({
        event,
        label,
        eventContext,
        monster_id: userId.value,
        [event]: data,
      });
    } catch (error) {
      console.log(error);
    }
  }

  function getGaClientId() {
    let gaClientId = null;
    const gaCookie = useCookie('_ga');

    if (!gaCookie.value) return gaClientId;

    const match = gaCookie.value.match(/(\d+\.\d+)$/);
    gaClientId = match ? match[1] : null;

    return gaClientId;
  }

  return { setUserId, pushEvent, getGaClientId };
};
