export const accountLoginAdapter = (data) => {
  return {
    token: data.token,
    isGuest: data.is_guest,
    accountId: data.account_id,
    authCodeId: data.auth_code_id,
    countryId: data.country_id,
    currencyId: data.currency_id,
  };
};

export const accountAdapter = (data) => {
  return {
    email: data.email,
    isGuest: data.is_guest,
    defaultLocale: data.default_locale,
    hasActiveCartTickets: data.has_active_cart_tickets,
    hasActiveOrderTickets: data.has_active_order_tickets,
    hasBoughtTickets: data.has_bought_tickets,
  };
};
