// import {
//   LANGUAGE_COOKIE_KEY,
//   I18N_LOCALES,
//   I18N_DEFAULT_LOCALE,
// } from '@/constants/languages';
//
// function getLocaleFromPath(path) {
//   const possibleLocale = path.split('/')[1];
//
//   const isLocale = I18N_LOCALES.some(
//     (locale) => locale.code === possibleLocale,
//   );
//
//   return {
//     locale: isLocale ? possibleLocale : I18N_DEFAULT_LOCALE,
//     isDefaultAndHasPrefix: possibleLocale === I18N_DEFAULT_LOCALE,
//   };
// }
//
// function getSlicedPath(path) {
//   return path.split('/').slice(2).join('/');
// }
// function getCleanPath(path) {
//   if (!path.startsWith('/')) {
//     path = `/${path}`;
//   }
//   if (path.startsWith(`/${I18N_DEFAULT_LOCALE}`)) {
//     path = `/${getSlicedPath(path)}`;
//   }
//
//   return path === '/' ? '' : path;
// }
//
// function switchLocaleInPath({ path = '/', localeFrom, localeTo }) {
//   if (localeFrom === I18N_DEFAULT_LOCALE) {
//     if (localeTo === I18N_DEFAULT_LOCALE) {
//       return `${getCleanPath(getSlicedPath(path))}` || '/';
//     } else {
//       return `/${localeTo}${getCleanPath(path)}`;
//     }
//   } else {
//     if (localeTo === I18N_DEFAULT_LOCALE) {
//       return `${getCleanPath(getSlicedPath(path))}` || '/';
//     } else {
//       return `/${localeTo}${getCleanPath(getSlicedPath(path))}`;
//     }
//   }
// }

export default defineNuxtRouteMiddleware(() =>
  // to
  {
    // const nuxtApp = useNuxtApp();
    //
    // if (
    //   import.meta.client &&
    //   (!nuxtApp.isHydrating || nuxtApp.payload.serverRendered)
    // )
    //   return;
    //
    // const localeCookie = useCookie(LANGUAGE_COOKIE_KEY);
    // if (!localeCookie.value) return;
    //
    // const { locale: localeTo, isDefaultAndHasPrefix } = getLocaleFromPath(
    //   to.path,
    // );
    //
    // if (localeTo !== localeCookie.value || isDefaultAndHasPrefix) {
    //   const redirectPath = switchLocaleInPath({
    //     path: to.path,
    //     localeFrom: localeTo,
    //     localeTo: localeCookie.value,
    //   });
    //
    //   return redirectToPath(redirectPath);
    // }
  },
);
