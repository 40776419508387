import qs from 'qs';
import { DateTime } from 'luxon';
import { I18N_REDIRECT_ROUTE, LANGUAGE_CODES } from '../constants/languages';

// common
export function minMax(value, min, max) {
  return Math.min(Math.max(value, min), max);
}

export function cloneObject(object) {
  return JSON.parse(JSON.stringify(object));
}

export function isObjectsSame(firstObject, secondObject) {
  return JSON.stringify(firstObject) === JSON.stringify(secondObject);
}

// promise-based utils
export function awaitTimeout(duration = 0) {
  return new Promise((resolve) => setTimeout(resolve, duration));
}

export function awaitRAF() {
  return new Promise((resolve) => requestAnimationFrame(resolve));
}

// em font-sizing
export function getBodyFz() {
  const bodyStyle = window.getComputedStyle(document.body);
  return parseFloat(bodyStyle.fontSize);
}

export function toResizedPx(pxValue) {
  return (pxValue / 16) * getBodyFz();
}

export function toResizedEm(pxValue, pxContext = 16) {
  return pxValue / pxContext;
}

// preload images
export function preloadImage(url) {
  return new Promise((resolve) => {
    const image = new Image();
    image.src = url;

    image.addEventListener('load', resolve);
    image.addEventListener('error', resolve);
  });
}

export function preloadImages(imageUrls = []) {
  const preloads = Array.from(imageUrls).map(preloadImage);

  return Promise.all(preloads);
}

// preload fonts
export function getFontPreloadList({ path, weights }, baseUrl = '/') {
  return weights.map((weight) => ({
    rel: 'preload',
    href: `${baseUrl}fonts/${path}${weight}.woff2`,
    as: 'font',
    type: 'font/woff2',
    crossorigin: true,
  }));
}

export function getFontsPreloadList(fontsList) {
  return fontsList.reduce(
    (result, { path, weights }) => [
      ...result,
      ...getFontPreloadList({ path, weights }),
    ],
    [],
  );
}

// qs
export function qsStringifyValues(object, { strictNullHandling = true } = {}) {
  return qs.stringify(object, {
    allowEmptyArrays: true,
    strictNullHandling,
  });
}
export function qsParseAndDecodeValues(
  string,
  { strictNullHandling = true } = {},
) {
  return qs.parse(string, {
    allowEmptyArrays: true,
    strictNullHandling,
    decoder(str, defaultDecoder, charset, type) {
      if (type === 'key') {
        return defaultDecoder(str);
      }

      if (/^(\d+|\d*\.\d+)$/.test(str)) {
        return parseFloat(str);
      }

      const keywords = {
        true: true,
        false: false,
        null: null,
        undefined: undefined,
      };
      if (str in keywords) {
        return keywords[str];
      }

      return defaultDecoder(str);
    },
  });
}

// datetime
export function formatFilterDate(date) {
  return date.toFormat('yyyy-MM-dd');
}
export function formatTimeWithZone(dateTimeString) {
  return DateTime.fromISO(dateTimeString, { setZone: true }).toFormat('HH:mm');
}

// uuid
export function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

// redirect
export function redirectToPath(path) {
  return navigateTo(path === '/' ? `/${I18N_REDIRECT_ROUTE}` : path);
}

// make columns from array
export function groupItemsToColumns({
  maxColumns = 4,
  minColumnSize = 1,
  items,
}) {
  const totalItems = items.length;

  const numberOfColumns = Math.min(
    maxColumns,
    Math.floor(totalItems / minColumnSize),
  );

  // If there are not enough items to create columns
  if (numberOfColumns === 0) return [items];

  const result = [];
  const columnSize = Math.floor(totalItems / numberOfColumns);

  // Add items to columns
  for (let index = 0; index < numberOfColumns; index++) {
    const start = index * columnSize;
    const end = start + columnSize;
    result.push(items.slice(start, end));
  }

  items.slice(numberOfColumns * columnSize).forEach((remainingItem, index) => {
    result[index % numberOfColumns].push(remainingItem);
  });

  return result;
}

// i18n
export function clearLocaleFromPath(path) {
  const localesRegex = new RegExp(`^/(${LANGUAGE_CODES.join('|')})`);

  return path.replace(localesRegex, '');
}

// images
export function getSizedImageSrc(src, size) {
  const storageIndex = src.indexOf('/storage');

  if (storageIndex === -1) return src;

  const beforeStorage = src.slice(0, storageIndex + 8);
  const afterStorage = src.slice(storageIndex + 8);

  return `${beforeStorage}/${size}x${size}${afterStorage}`;
}

// adapters
export async function getSectionAdapters(sections, adapters) {
  const sectionAdapterImports = sections.map(async (section) => {
    const { type } = section;

    const adapterName = adapters[type];
    if (adapterName === false) return { type };

    try {
      const adapterImport = await import(`@/adapters/seo/${adapterName}.js`);

      try {
        const data = await adapterImport.default(section);

        return {
          type,
          data,
        };
      } catch (error) {
        return {
          type,
          error: `adapter error: ${error.toString()}`,
        };
      }
    } catch (error) {
      return {
        type,
        error: 'missing adapter',
      };
    }
  });

  return Promise.all(sectionAdapterImports);
}
export function filterAdaptedSections(sections, components, source) {
  return sections?.filter(({ error, type }) => {
    if (error) {
      if (process.client) {
        console.warn(`${source}: "${type}": ${error}`);
      }
      return false;
    }

    if (!components[type]) {
      if (process.client) {
        console.warn(`${source}: "${type}": missing component`);
      }
      return false;
    }

    return true;
  });
}

// string manipulation
export function capitalizeFirstLetter(string) {
  if (!string || !string.length) return;

  return string.charAt(0).toUpperCase() + string.slice(1);
}
