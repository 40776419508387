<script setup>
import { ModalsContainer } from 'vue-final-modal';

const props = defineProps({
  error: {
    type: Object,
    default: () => ({}),
  },
});
if (process.server) {
  const error = props.error;
  console.error({
    'errorData===': error.message,
    statusCode: error.statusCode,
    statusMessage: error.statusMessage,
    url: error.url,
    stack: error.stack,
  });
}
const { t } = useI18n();
</script>

<template>
  <div id="app" class="error">
    <NuxtLayout hide-footer-contacts class="error__layout" name="default">
      <SectionErrorIntro
        class="error__intro"
        :title="t(`error.${error.statusCode}`)"
      />
      <ModalsContainer />
    </NuxtLayout>
  </div>
</template>

<style scoped lang="scss">
.error {
  $parent: &;

  display: flex;
  flex-direction: column;
  background-color: $color-gray-5;

  &:deep(#{$parent}__layout) {
    flex-grow: 1;
  }

  &__intro {
    @include media-breakpoint-up(sm) {
      height: em(756);
    }
  }
}
</style>
