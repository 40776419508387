// Initial server data

// * renamed from initial-data.server.js due to plugin not start
// in pages with routeRules: 'ssr: false'

export default defineNuxtPlugin({
  parallel: true,
  async setup() {
    const requests = [];

    // currency
    const currencyStore = useCurrencyStore();

    const currencyIdCookie = useCookie('currencyId');
    const currencyId = currencyIdCookie.value;

    if (currencyId) {
      currencyStore.setCurrencyId(currencyId);
    }
    if (!currencyStore.currenciesFetched) {
      requests.push(currencyStore.fetchCurrencies());
    }

    await Promise.allSettled(requests);

    // // cart
    // const cartStore = useCartStore();
    //
    // if (!cartStore.cart) {
    //   requests.push(cartStore.fetchCart());
    // }
    //
    // await Promise.allSettled(requests);
  },
});
