export default {
  USER_ID_SET: 'user_id_set',
  HOMEPAGE_VISIT: 'homepage_visit',
  SEARCH_FORM_INTERACTION: 'search_form_interaction',
  ENTER_SEARCH_DETAILS: 'enter_search_details',
  CLICK_SEARCH_BUTTON: 'click_search_button',
  VIEW_ITEM_LIST: 'view_item_list',
  VIEW_ITEM: 'view_item',
  VIEWED_TICKET_OPTIONS: 'viewed_ticket_options',
  ADD_TO_CART: 'add_to_cart',
  DELETE_ONE_TICKET: 'delete_one_ticket',
  DELETE_ALL_TICKETS: 'delete_all_tickets',
  ENTER_PASSENGER_DETAILS: 'enter_passenger_details',
  CLICK_CHECKOUT: 'click_checkout',
  ENTER_MOBILE_NUMBER: 'enter_mobile_number',
  ENTER_EMAIL: 'enter_email',
  CHECKOUT: 'begin_checkout',
  SUCCESSFUL_PURCHASE: 'purchase',
  UNSUCCESSFUL_PURCHASE: 'unsuccessful_purchase',
  MENU_NAVIGATE: 'menu_navigate',
};
