import qs from 'qs';
import requestIp from 'request-ip';

let fetchInstance;

export const useRequest = (url, options = {}, apiVersion = 'v1') => {
  if (!fetchInstance) {
    let nuxtApp;
    try {
      nuxtApp = useNuxtApp();
    } catch (error) {
      // ignore
    }

    const {
      app: { baseURL },
      public: { clientUrl },
    } = useRuntimeConfig();

    // configuring url
    const apiPrefix = 'api';

    let baseUrl = baseURL || '/';

    if (import.meta.server) {
      // server url
      const { serverUrl } = useRuntimeConfig();
      baseUrl = serverUrl;
    } else if (!import.meta.dev) {
      // client url only on prod (proxy for dev)
      baseUrl = clientUrl;
    }

    fetchInstance = $fetch.create({
      baseURL: `${baseUrl}${apiPrefix}`,
      onRequest({ options }) {
        const headers = {};

        if (process.server) {
          try {
            const event = useRequestEvent();

            // ip proxy
            const ip = requestIp.getClientIp(event.node.req);
            if (ip) {
              headers['X-Forwarded-For'] = ip;
            }

            // country proxy
            const cfIpCountry = event.node.req.headers['cf-ipcountry'];
            if (cfIpCountry) {
              headers['Cf-Ipcountry'] = cfIpCountry;
            }
          } catch (error) {
            // ignore
          }
        }

        if (!options?.headers?.['Accept-Language']) {
          try {
            const { locale } = useI18n();
            if (locale.value) headers['Accept-Language'] = locale.value;
          } catch (error) {
            try {
              headers['Accept-Language'] = nuxtApp.$i18n.locale.value;
            } catch (error) {
              // ignore
            }
          }
        }

        const authStore = useAuthStore();
        if (authStore.token) {
          headers.Authorization = `Bearer ${authStore.token}`;
        }

        options.headers = { ...headers, ...options.headers };
      },
      onResponseError(error) {
        if (process.server) return;

        if (error.response.status === 500) {
          const { message } = error.response._data;
          alert(`${nuxtApp.$i18n.t('default.errors.500')}\n\n${message}`);
        }
      },
    });
  }

  if (options.query) {
    url = `${url}?${qs.stringify(options.query)}`;

    options.query = undefined;
  }

  // need to use useCookie before any async action
  // issue: https://github.com/nuxt/nuxt/issues/13965
  let tokenCookie;
  let countryIdCookie;
  let currencyIdCookie;

  try {
    tokenCookie = useCookie('token');
    countryIdCookie = useCookie('countryId');
    currencyIdCookie = useCookie('currencyId');
  } catch (error) {
    // ignore
  }

  // set cookies on server
  if (process.server) {
    try {
      const cookieHeader = useRequestHeader('cookie');

      if (cookieHeader) {
        if (!options.headers) options.headers = {};

        options.headers['Cookie'] = cookieHeader;
      }
    } catch (error) {
      // ignore
    }
  }

  return fetchInstance(`${apiVersion}${url}`, options).catch(async (error) => {
    if (error.status === 403) {
      const authStore = useAuthStore();

      await authStore
        .fetchGuestToken({ tokenCookie, countryIdCookie, currencyIdCookie })
        .catch(() => {});
    }

    throw error;
  });
};
