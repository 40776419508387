<script setup>
import { IMAGE_PATH } from '@/constants/images';

defineProps({
  title: {
    type: String,
    default: undefined,
  },
});

const formCollapseStore = useFormCollapseStore();
</script>

<template>
  <section
    class="section-error-intro"
    :class="{
      'section-error-intro--form-collapsed': !formCollapseStore.isFormOpened,
    }"
  >
    <Link
      rel="preload"
      as="image"
      :href="`${IMAGE_PATH}/error/background.png`"
    />
    <Link
      rel="preload"
      as="image"
      :href="`${IMAGE_PATH}/error/background-mobile.jpeg`"
    />

    <div class="section-error-intro__container container container--safe">
      <div class="section-error-intro__heading">
        <h1
          class="section-error-intro__font section-error-intro__font--heading"
          v-html="title"
        />
      </div>

      <CImage
        class="section-error-intro__logos"
        src="/images/main/logos.png"
        alt="logos"
      />
    </div>

    <CImage
      src="/images/main/monster.png"
      class="section-error-intro__monster"
      alt="Monster"
    />
    <div class="section-error-intro__railroad hidden-desktop" />
  </section>
</template>

<style scoped lang="scss">
.section-error-intro {
  $parent: &;

  position: relative;
  display: flex;
  flex-direction: column;
  padding: em(205) 0 em(54);
  background-color: $color-main-1;
  background-image: url($images-path + '/error/background.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @include media-breakpoint-down(sm) {
    padding: em(79) 0 em(27);
    background-image: url($images-path + '/error/background-mobile.jpeg');
  }

  &__font {
    &--heading {
      font-size: em(56);
      font-weight: 300;
      line-height: math.div(56, 56);

      @include media-breakpoint-down(sm) {
        font-size: em(24);
        font-weight: 300;
        line-height: math.div(28, 24);
      }
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__heading {
    max-width: em(870);
    font-size: em(16);
    color: $color-white;

    @include media-breakpoint-down(sm) {
      max-width: em(220);
      margin-bottom: em(80);
    }
  }

  &__logos {
    width: em(500);
    height: em(127);
    margin-top: auto;
    object-fit: contain;

    @include media-breakpoint-down(sm) {
      width: em(278);
      height: em(40);
    }
  }

  &__monster {
    position: absolute;
    top: em(246);
    left: em($offset-small-monster);
    z-index: 7;
    width: em(281);
    height: em(315);
    pointer-events: none;

    @include media-breakpoint-down(sm) {
      top: em(30);
      right: em(-12);
      left: unset;
      width: em(167);
      height: em(202);
    }
  }

  &__railroad {
    position: absolute;
    right: 0;
    bottom: em(-35);
    left: 0;
    z-index: 6;
    height: em(194);
    pointer-events: none;
    background-image: url($images-path + '/main/railroads/railroad-1-mobile.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    @include media-breakpoint-down(sm) {
      bottom: 0;
    }
  }

  &--form-collapsed {
    @include media-breakpoint-down(sm) {
      padding-top: em($header-height-sm + 79);
    }

    #{$parent} {
      &__monster {
        @include media-breakpoint-down(sm) {
          top: em($header-height-sm + 30);
        }
      }
    }
  }
}
</style>
