export default {
  MAIN: '/',
  ABOUT: '/about',
  APP: '/#mobile-app',
  HELP: '/help',
  HELP_ON_BOARD: '/help/on-board-information',
  HELP_COUNTRY_SPECIFIC: '/help/country-specific-train-guides',
  CONTACT: '/contact',
  FORM: '/#form',
  CONTACT_FORM: '/contact#form',
  TERMS: '/terms',
  SECURITY: '/',
  PRIVACY: '/rules',
  FAQ: '/faq',
  TRAINS: {
    EUROPEAN: '/eu-trains',
    EUROSTAR: '/train',
  },
  SEARCH: '/search',
  MY_TICKETS: '/my-tickets',
  CART: '/cart',
  CART_PROCESSING: '/cart/processing',
  AGREEMENT: '/',
  MOBILE_APPS: '/mobile-app',
  WIKI: '/trains',
  REFUND_POLICY: '/refund-policy',

  APP_STORE:
    'https://apps.apple.com/us/app/rail-monsters-train-tickets/id6472889448',
  GOOGLE_PLAY: 'https://play.google.com/store/apps/details?id=com.railmonsters',
  FACEBOOK: 'https://facebook.com/',
  X: 'https://x.com/railmonsters',
  YOUTUBE: 'https://www.youtube.com/@RailMonsters',
  TIKTOK: 'https://www.tiktok.com/@railmonsters',
  INSTAGRAM: 'https://www.instagram.com/rail.monster/',
};
