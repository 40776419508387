import { Centrifuge } from 'centrifuge';

class CentrifugeService {
  #centrifuge;
  #subscription;
  init(wsEndpoint) {
    this.#centrifuge =
      wsEndpoint && new Centrifuge(wsEndpoint, { debug: false });
  }

  hasSubscription() {
    return !!this.#subscription;
  }

  connect(channel, callback) {
    try {
      this.#createSubscribeToChannel(channel);
      this.#listenPublication(callback.resolve);
      this.#listenError(callback.reject);
      this.#connectToChannel();
    } catch (error) {
      console.error('CentrifugeService.connect', error);
      callback.reject();
    }
  }

  #createSubscribeToChannel(channel) {
    if (!this.#centrifuge || !channel) return;

    this.#subscription = this.#centrifuge.newSubscription(channel);
  }

  #listenPublication(callback) {
    this.#subscription && this.#subscription.on('publication', callback);
  }

  #listenError(callback) {
    this.#centrifuge && this.#centrifuge.on('error', callback);
  }

  #connectToChannel() {
    if (!this.#centrifuge || !this.#subscription) return;

    this.#subscription.subscribe();
    this.#centrifuge.connect();
  }

  disconnect() {
    this.#subscription?.unsubscribe?.();
    this.#subscription?.removeAllListeners?.();
    this.#centrifuge?.removeSubscription?.(this.#subscription);
    this.#centrifuge?.disconnect?.();
  }
}

export const WS = new CentrifugeService();
