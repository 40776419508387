import { getDeviceCustomData } from '@/plugins/user-agent-custom';

export default defineNuxtPlugin({
  parallel: true,
  async setup(nuxtApp) {
    const requests = [];

    const authStore = useAuthStore();

    const tokenCookie = useCookie('token');
    const countryIdCookie = useCookie('countryId');
    const token = tokenCookie.value;
    const countryId = countryIdCookie.value;

    if (token) {
      authStore.setAuthData({ token, countryId }, false);
    }

    if (!authStore.account) {
      if (!authStore.token) {
        const currencyIdCookie = useCookie('currencyId');
        const device = getDeviceCustomData(nuxtApp);

        requests.push(
          authStore.fetchGuestToken({
            tokenCookie,
            countryIdCookie,
            currencyIdCookie,
            userDevice: device,
          }),
        );
      } else {
        requests.push(authStore.fetchAccount());
      }
    }

    await Promise.allSettled(requests);
  },
});
